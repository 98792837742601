import apiClient from "@/core/apiClient.js";

export class CatalogueRepository {

  async fetchCatalogue(latitude, longitude, filters) {
    try {
      const {data} = await apiClient.post(`customer/establishments`, {
        lat: latitude,
        long: longitude,
        filters: filters
      });

      return data.data;
    } catch (error) {
      console.error('Failed to fetch catalogue:', error);
      throw new Error(error);
    }
  }

  async fetchEstablishment(establishment_slug, longitude, latitude) {
    try {
      const {data} = await apiClient(`customer/establishment/${establishment_slug}?latitude=${latitude}&longitude=${longitude}`);
      return data.data;
    } catch (error) {
      console.error('Failed to fetch establishment:', error);
      throw new Error(error);
    }
  }
}
