//import apiClient from "@/core/apiClient.js";
import axios from "axios";
import apiClient from "@/core/apiClient.js";

export class UserAuthenticationRepository {
  async getToken(payload) {
    try {
      const response= await axios.post(`${process.env.API_URL}/oauth/token`, {
        grant_type: 'password',
        client_id: process.env.API_KEY_ID,
        client_secret: process.env.API_KEY_SECRET,
        username: payload.email,
        password: payload.password
      });

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  }

  async getUser(token) {
    try {
      const {data} = await axios.get(`${process.env.API_URL}/v1/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return data.data;
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  }

  /* -------------------------------------------
  /  LOG IN
  */

  async loginWithFacebook(token) {
    try {
      const {data} = await apiClient.post('facebook', {
        token: token}
      );

      return data.data;
    } catch (error) {
      console.error('Failed to login with Facebook:', error);
      throw new Error(error);
    }
  }

  async loginWithGoogle(token) {
    try {
      const {data} = await apiClient.post('google', {
        token: token}
      );

      return data.data;
    } catch (error) {
      console.error('Failed to login with Google:', error);
      throw new Error(error);
    }
  }

  async loginWithApple(token, userResponse) {
    try {
      const {data} = await apiClient.post('apple', {
        token: token,
        user_data: userResponse
      });

      return data.data;
    } catch (error) {
      console.error('Failed to login with Apple:', error);
      throw new Error(error);
    }
  }
}
