import { Drivers, Storage } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {AppConfiguration} from "@/modules/app-configuration/models/AppConfiguration.js";

// Storage keys as constants
const STORAGE_KEYS = {
  APP_CONFIGURATION: 'app_configuration',
};

export class AppConfigurationStore {
  constructor() {
    this.store = new Storage({
      driverOrder: [Drivers.IndexedDB, CordovaSQLiteDriver._driver],
    });

    this.store.create();
  }

  async set(key, value) {
    try {
      const config = await this.getAppConfiguration();
      if (config[key] === value) return;
      config[key] = value;
      await this.setAppConfiguration(config);
    } catch (error) {
      console.error(`Failed to set app configuration property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const config = await this.getAppConfiguration();
      return config[key];
    } catch (error) {
      console.error(`Failed to get app configuration property "${key}":`, error);
      return undefined;
    }
  }

  async setAppConfiguration(configuration) {
    try {
      await this.store.set(STORAGE_KEYS.APP_CONFIGURATION, configuration);
    } catch (error) {
      console.error('Failed to set app configuration:', error);
    }
  }

  async getAppConfiguration() {
    try {
      const storedConfig = await this.store.get(STORAGE_KEYS.APP_CONFIGURATION);

      return storedConfig ? new AppConfiguration(storedConfig) : null;

    } catch (error) {
      console.error('Failed to retrieve app configuration:', error);
      return {};
    }
  }


  async clearAppConfiguration() {
    try {
      await this.store.remove(STORAGE_KEYS.APP_CONFIGURATION);
    } catch (error) {
      console.error('Failed to clear app configuration:', error);
    }
  }
}
