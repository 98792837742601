export class CatalogueFilter {
  constructor(data)  {
    this.reset();

    if(data){
      this.init(data);
    }
  }

  init(data) {
    this.toggles = data.toggles || this.toggles;
    this.types = data.types || this.types;
    this.categories = data.categories || this.categories;
  }

  reset() {
    this.toggles = {
      delivery: true,
      open: false,
      ready2help: false
    };

    this.types = {
      all: true,
      restaurant: false,
      shop: false,
      night_shop: false,
    }

    this.categories = [
      { key: 'all', active: true },
      { key: 'kebab', active: false },
      { key: 'pizza', active: false },
      { key: 'burgers', active: false },
      { key: 'pasta', active: false },
      { key: 'asian', active: false },
      { key: 'sushi', active: false },
      { key: 'snacks', active: false },
      { key: 'drinks', active: false },
      { key: 'sandwiches', active: false },
      { key: 'fries', active: false },
      { key: 'groceries', active: false },
    ];
  }

  toggle(key) {
    this.toggles[key] = !this.toggles[key];
  }

  toggleType(key) {
    if(key === 'all'){
      for (let key in this.types) {
        this.types[key] = false;
      }

      this.types.all = true;
    } else {
      this.types.all = false;
      this.types[key] = !this.types[key];
    }
  }

  toggleCategory(key) {
   if(key === 'all'){
     this.categories.forEach(category => category.active = false);
     this.categories.find(category => category.key === 'all').active = true;
   } else {
      this.categories.find(category => category.key === 'all').active = false;

      const category = this.categories.find(category => category.key === key);
      category.active = !category.active;

   }
  }

  transform() {
    return {
      ready2help: this.toggles.ready2help,
      open: this.toggles.open,
      delivery: this.toggles.delivery,
      types: Object.keys(this.types).filter(key => this.types[key]),
      categories: this.categories.filter(category => category.active).map(category => category.key)
    }
  }
}
