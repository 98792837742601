export class DiscountCode {
  constructor(data) {
    this.id = data.id || null;
    this.code = data.code || null;

    this.min_order_amount = data.min_order_amount;
    this.discount_amount = data.discount_amount;

    this.auto_apply = data.auto_apply || false;
    this.ignore_partner_min = data.ignore_partner_min || false;

    this.max_uses = data.max_uses || null;
    this.max_uses_per_user = data.max_uses_per_user || null;

    this.valid_from = data.valid_from || null;
    this.valid_to = data.valid_to || null;

    this.usage_count = data.usage_count || 0;

    this.enabled = data.enabled;

    this.dismissed = data.dismissed || false;
    this.applied = data.auto_apply ? true : (data.applied || false);
  }

  getAmount() {
    return this.discount_amount;
  }

  incrementUsage() {
    this.usage_count++;
  }
}
