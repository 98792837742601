import apiClient from "@/core/apiClient.js";

export class ArticleRepository {

  async fetchArticle(establishment_slug, id){
    try {
      const {data} = await apiClient(`customer/establishment/${establishment_slug}/article/${id}`);

      return data.data;
    } catch (error) {
      console.error('Failed to fetch article:', error);
      throw new Error(error);
    }
  }
}
