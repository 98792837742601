export default class SocialAuthenticationHelper {
    async loginWithGoogle() {
        throw new Error('loginWithGoogle method must be implemented');
    }

    async loginWithFacebook() {
        throw new Error('loginWithFacebook method must be implemented');
    }

    async loginWithApple() {
        throw new Error('loginWithApple method must be implemented');
    }
}
