<template>
  <span class="toggle-delivery" :class="{ 'only-delivery': onlyDelivery }">
    <b :class="{ active: deliveryMode === 'delivery' }">
      {{ deliveryMode === 'delivery' ? __t('app.delivery') : __t('app.takeaway') }}
    </b>
    <Toggle
      class="delivery-toggle"
      :active="deliveryMode === 'delivery'"
      @toggle="toggleDelivery"
    />
    <Tooltip position="left">{{ __t('app.no-delivery') }}</Tooltip>
  </span>
</template>

<script setup>
  import {onBeforeMount, ref} from 'vue';
  import { Toggle } from "@/ui";
  import { __t } from "@/globals";
  import Tooltip from "@/modules/global/parts/Tooltip.vue";
  import {cartService} from "@/services/app/cartService.js";

  const deliveryMode = ref('takeout');
  const establishment = ref(null);
  const onlyDelivery = ref(false);

  const checkDeliveryMode = async () => {
    deliveryMode.value = await cartService.getDeliveryMode();
    establishment.value = await cartService.getEstablishment();

    onlyDelivery.value = establishment.value?.delivery === 'delivery';

    if(deliveryMode.value !== 'delivery' && onlyDelivery.value){
      await cartService.setDeliveryMode('delivery');
    }
  }

  onBeforeMount(async () => {
    await checkDeliveryMode();

    document.addEventListener('delivery_mode:updated',  checkDeliveryMode);
  });

  const toggleDelivery = async () => {
    deliveryMode.value = deliveryMode.value === 'delivery' ? 'takeaway' : 'delivery';
    await cartService.setDeliveryMode(deliveryMode.value);
  };

</script>

<style lang="scss">
.toggle-delivery {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  &.only-delivery {
    opacity: 0.4;
    pointer-events: none;
  }

  &.disabled {
    position: relative;

    .toggle, b {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      .tooltip {
        display: flex;
        right: 20%;
      }
    }
  }

  b {
    margin-right: var(--margin-xs);
    font-weight: 600;

    &.active {
      color: var(--r2e-primary);
    }
  }
}
</style>
