import apiClient from "@/core/apiClient.js";

export class UserRepository {

  async requestUserDeletion(email) {
    try {
      const {data} = await apiClient.post('/gdpr/request-account-delete', {email});

      return data.data;
    } catch (error) {
      console.error('Failed to delete account:', error);
      throw error;
    }
  }

  async updateUser(payload) {
    try {
      const {data} = await apiClient.post('/customer/account/update', payload);

      return data.data;
    } catch (error) {
      console.error('Failed to update account:', error);
      throw error;
    }
  }
}
