<template>
  <button ref="trigger" class="order-follow-button" @click="handleSubmit">
    <Icon name="bag" />
    <span class="text">
      {{ currentOrders.length > 1 ? __t('order.follow.orders.button') : __t('order.follow.order.button') }}
    </span>

    <span v-if="currentOrders.length > 1" class="counter ml-auto">
      {{ currentOrders.length }}
    </span>
    <span v-else class="order-number ml-auto">
      #{{ currentOrders[0]?.nr }}
    </span>
  </button>

  <ion-popover
    :is-open="isPopoverOpen"
    :trigger="trigger"
    class="order-follow-popover"
    @didDismiss="isPopoverOpen = false"
  >
    <ion-content class="ion-padding">
      <div class="order-list">
        <div
          v-for="order in currentOrders"
          :key="order.nr"
          class="order-item"
          @click="selectOrder(order)"
        >
          <Icon name="bag" />

          <span class="text">
            {{ __t('order.follow.orders.button') }}
          </span>

          <span class="order-nr">#{{ order.nr }}</span>
        </div>
      </div>
    </ion-content>
  </ion-popover>
</template>

<script setup>
  import {__t} from "@/globals.js";
  import {Icon} from "@/ui/index.js";
  import {onBeforeMount, ref} from "vue";
  import {userService} from "@/services/app/userService.js";
  import {useRouter} from "vue-router";
  import {modalController, IonPopover, IonContent} from "@ionic/vue";

  const currentOrders = ref([]);
  const router = useRouter();
  const trigger = ref();
  const isPopoverOpen = ref(false);

  const selectOrder = async (order) => {
    isPopoverOpen.value = false;

    await router.push({name: 'order-follow-by-nr', params: {nr: order.nr}});

    await modalController.dismiss();
  };

  const handleSubmit = async () => {
    if (currentOrders.value.length === 1) {
      await router.push({name: 'order-follow-by-nr', params: {nr: currentOrders.value[0].nr}});

      await modalController.dismiss();
    } else {
      isPopoverOpen.value = true;
    }
  }

  onBeforeMount(async () => {
    currentOrders.value = await userService.getCurrentOrders();
  });
</script>

<style lang="scss">
.order-follow-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  max-width: none;
  border: 2px solid var(--r2e-primary-100);
  background-color:transparent;
  border-radius: 1rem;
  padding: 0 var(--padding-m);
  cursor: pointer;
  transition: var(--effect);

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: var(--margin-s);
    color: var(--r2e-secondary);
  }

  span.text {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--r2e-secondary);
    margin: 0;
  }

  span.counter {
    // make a circle with a background color
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--r2e-primary);
    color: var(--r2e-white);
    font-size: 1.2rem;
    font-weight: 500;
  }

  &:active {
    transform: scale(0.97);
  }

  span.order-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    border-radius: 4px;
    font-weight: 600;
    padding: 0 var(--padding-s);
    background-color: var(--r2e-secondary-100);
    color: var(--r2e-secondary);
    font-size: 1.2rem;
  }
}

.order-follow-popover {
  --width: 100%;
  --max-width: 30rem;
}

.order-list {
  .order-item {
    padding: 1rem;
    border-bottom: 1px solid var(--r2e-secondary-100);
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;

    svg {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: var(--margin-s);
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: var(--r2e-secondary-100);
    }

    span.text {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary);
      margin: 0;
    }

    .order-nr {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      border-radius: 4px;
      font-weight: 600;
      padding: 0 var(--padding-s);
      background-color: var(--r2e-secondary-100);
      color: var(--r2e-secondary);
      font-size: 1.2rem;
    }
  }
}

// dark mode
[data-theme='dark'] {
  .order-follow-button {
    border: 2px solid var(--r2e-primary);
    background-color: var(--r2e-primary-700);
    border-radius: 1rem;
    padding: 0 var(--padding-m);
    cursor: pointer;
    transition: var(--effect);

    svg {
      color: var(--r2e-primary);
    }

    span.text {
      color: var(--r2e-primary);
    }

    span.counter {
      background-color: var(--r2e-primary);
      color: var(--r2e-white);
    }

    span.order-number {
      background-color: var(--r2e-primary);
      color: var(--r2e-secondary);
    }
  }

  .order-follow-popover {
    .order-list {
      .order-item {
        border-color: var(--r2e-secondary);

        svg {
          color: var(--r2e-secondary-200);
        }

        span.text {
          color: var(--r2e-secondary-200);
        }

        span.order-nr {
          background-color: var(--r2e-primary-700);
          color: var(--r2e-primary);
        }
      }
    }
  }

}
</style>
