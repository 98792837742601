<template>
  <div class="r2e-input search-input" style="height: 6.1rem">
    <label v-if="label" :for="id">{{ label }}</label>
    <div class="input-field search-field">
      <input
        :id="id"
        ref="searchInput"
        v-model="location"
        type="text"
        class="google-search-location"
        :placeholder="placeholder ? placeholder : __t('app.banner.search.placeholder')"
        @keydown.enter.prevent="handleEnter"
      >
      <button class="search" @click="emit('submit')">
        <Icon name="search" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, onUnmounted, defineEmits, defineProps } from 'vue';
  import { __t } from "@/globals";
  import Icon from "@/ui/icon/Icon.vue";
  import Address from "@/resources/Address.js";
  import {addressService} from "@/flows/app/address/services/AddressService.js";

  const props = defineProps({
    placeholder: { type: String, required: false, default: null },
    autoSubmit: { type: Boolean, required: false, default: true },
    label: { type: String, required: false, default: null },
    id: { type: String, required: true }
  });

  const emit = defineEmits(['addressSelected', 'submit']);

  const apiKey = process.env.GOOGLE_MAPS_WEB_API_KEY;
  const location = ref('');
  const searchInput = ref(null);
  let autocomplete = null;

  const handleEnter = () => {
    emit('submit');
  };

  const initAutocomplete = () => {
    if (!window.google || !searchInput.value) return;

    autocomplete = new window.google.maps.places.Autocomplete(searchInput.value, {
      types: ['address'],
      componentRestrictions: { country: 'be' }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;

      const addressComponents = {};
      place.address_components.forEach(component => {
        const type = component.types[0];
        addressComponents[type] = component.long_name;
        if (type === 'country') {
          addressComponents.country_code = component.short_name;
        }
      });

      const formattedAddress = new Address({
        street: addressComponents.route || '',
        number: addressComponents.street_number || '',
        city: {
          name: addressComponents.locality || '',
          zip: addressComponents.postal_code || '',
          province: addressComponents.administrative_area_level_2 || '',
          country: addressComponents.country || 'België',
          country_code: addressComponents.country_code || 'BE'
        },
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });

      emit('addressSelected', formattedAddress);
      if (props.autoSubmit) {
        emit('submit');
      }
    });
  };

  const loadGoogleMapsScript = () => {
    if (window.google) {
      initAutocomplete();
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = initAutocomplete;
    document.head.appendChild(script);
  };

  onMounted(async () => {
    loadGoogleMapsScript();

    location.value = await addressService.getAddressString();
  });

  onUnmounted(() => {
    if (autocomplete) {
      window.google.maps.event.clearInstanceListeners(autocomplete);
    }
  });
</script>

<style lang="scss">
.r2e-input.search-input {
  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.6;
    color: var(--r2e-secondary);
    padding-bottom: 5px;
  }

  .input-field {
    position: relative;
  }

  .search-field {
    height: 6.1rem;
    position: relative;

    .google-search-location {
      background: var(--r2e-secondary-100);
      color: var(--r2e-secondary);
      font-size: 1.4rem;
      width: 100%;
      font-weight: 500;
      height: 6.1rem;
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 1.9rem 1.6rem;
      transition: var(--effect);

      &::placeholder {
        color: var(--r2e-secondary-200);
        font-weight: 500;
        opacity: 1;
      }

      &:hover {
        border: 1px solid var(--r2e-secondary-200);
      }

      &:focus {
        border: 1px solid var(--r2e-secondary);
        box-shadow: 0 0 0 1px var(--r2e-secondary);
      }
    }

    button.search {
      background-color: transparent;
      position: absolute;
      top: calc(50% - 1rem);
      right: var(--margin-m);
      z-index: 2;

      i svg {
        width: 2rem;
        height: 2rem;
        color: var(--r2e-secondary-200);
      }
    }
  }
}

[data-theme='dark'] {
  .r2e-input {
    label {
      color: var(--r2e-secondary-200);
    }

    .search-field {
      .google-search-location {
        background: var(--r2e-secondary-500);
        color: var(--r2e-white);

        &::placeholder {
          color: var(--r2e-secondary-200);
        }

        &:focus {
          border: 1px solid var(--r2e-secondary-200);
          box-shadow: 0 0 0 1px var(--r2e-secondary-200);
          caret-color: var(--r2e-secondary-200);
        }
      }
    }
  }
}

@keyframes blink-caret {
  from, to {
    caret-color: transparent;
  }
  50% {
    caret-color: var(--r2e-secondary);
  }
}
</style>
