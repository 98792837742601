
import {registerDataStorage} from "@/flows/user/authentication/stores/RegisterDataStorage.js";
import {UserRegistrationRepository} from "@/flows/user/authentication/repositories/UserRegistrationRepository.js";

class UserRegisterService {
  constructor() {
    this.userRegistrationRepository = new UserRegistrationRepository();
    this.registerDataStorage = registerDataStorage;
  }

  async registerByEmail({first_name, last_name, phone, email, password}) {
    try {
      const payload = {
        first_name,
        last_name,
        email,
        phone,
        password
      };

      // register user
      const response = await this.userRegistrationRepository.registerByEmail(payload);

      // set register data
      await this.registerDataStorage.setRegisterData({
        registering: true,
        email: payload.email,
        password: payload.password,
        user_id: response.user.id,
        provider: 'email',
      });

    } catch (error) {
      console.error('Error in register', error);
      throw new Error(error);
    }
  }

  async verifyRegisterCode(code, email) {
    try {
      await this.userRegistrationRepository.verifyRegisterCode(code, email);
    } catch (error) {
      console.error('Error in verifyRegisterCode', error);
      throw new Error(error);
    }
  }

  async resendVerificationCode(email) {
    try {
      await this.userRegistrationRepository.resendVerificationCode(email);
    } catch (error) {
      console.error('Error in resendVerificationCode', error);
      throw new Error(error);
    }
  }

  async getRegisterData() {
    try {
      return await this.registerDataStorage.getRegisterData();
    } catch (error) {
      console.error('Error in getRegisterData', error);
      throw new Error(error);
    }
  }

  async removeRegisterData() {
    try {
      await this.registerDataStorage.clear();
    } catch (error) {
      console.error('Error in removeRegisterData', error);
      throw new Error(error);
    }
  }

  async handleUserEmailVerification(user, provider) {
    try {
      await this.registerDataStorage.setRegisterData({
        registering: false,
        email: user.email,
        password: null,
        user_id: user.id,
        provider: provider,
      });
    } catch (error) {
      console.error('Error in handleRegisterData', error);
      throw new Error(error);
    }
  }
}

export const userRegisterService = new UserRegisterService();
