// services/preferencesStorage.js
import {Drivers, Storage} from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Storage keys as constants
const STORAGE_KEYS = {
  PREFERENCES: 'user_db',
};

class UserStorage {

  constructor() {
    this.storage = new Storage([Drivers.IndexedDB, CordovaSQLiteDriver._driver]);
    this.storage.create();
  }

  async setUser(preferences) {
    try {
      await this.storage.set(STORAGE_KEYS.PREFERENCES, preferences);
    } catch (error) {
      console.error('Failed to set user:', error);
    }
  }

  async getUser() {
    try {
      return await this.storage.get(STORAGE_KEYS.PREFERENCES) || {};
    } catch (error) {
      console.error('Failed to get user:', error);
      return {};
    }
  }

  async clear() {
    try {
      await this.storage.remove(STORAGE_KEYS.PREFERENCES);
    } catch (error) {
      console.error('Failed to clear user:', error);
    }
  }

  async set(key, value) {
    try {
      const settings = await this.getUser();
      settings[key] = value;
      await this.setUser(settings);
    } catch (error) {
      console.error(`Failed to set user property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const settings = await this.getUser();
      return settings[key];
    } catch (error) {
      console.error(`Failed to get user property "${key}":`, error);
    }
  }
}

export const userStorage = new UserStorage();
