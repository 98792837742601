import axios from "axios";
import {userService} from "../services/app/userService.js";
import PlatformService from "../services/PlatformService.js";
import {preferenceService} from "../services/app/preferenceService.js";

export const baseUrl = `${process.env.API_URL}/v1`;

const initHeaders = async () => {
  const tokenData = await userService.getAuthTokenData();
  const platform = PlatformService.getPlatform();
  const version = await preferenceService.getAppVersion();

  let headers =  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-App-Version': version,
    'X-App-Platform': platform,
    'X-API-KEY-ID': process.env.API_KEY_ID,
    'X-API-SECRET': process.env.API_KEY_SECRET,
    'Accept-Language': localStorage.getItem('locale') ?? 'nl',
    'locale': localStorage.getItem('locale') ?? 'nl',
  };

  if (tokenData?.access_token) {
    headers['Authorization'] = 'Bearer ' + tokenData.access_token;
  }

  return headers;
};

const apiClient = axios.create({
  baseURL: baseUrl,
});

// Add request interceptor to dynamically set headers
apiClient.interceptors.request.use(async (config) => {
  const headers = await initHeaders();
  config.headers = {
    ...config.headers,
    ...headers
  };
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiClient;
