<template>
  <ion-header style="padding-top: var(--ion-safe-area-top)" />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header">
        <h3>
          <Icon name="contact-user" />
          {{ __t('checkout.contact.title') }}
        </h3>
      </div>
      <div class="modal-main mb-l">
        <div class="modal-contact-fields">
          <form @submit.prevent>
            <div class="input-group group-2">
              <Input
                id="first_name"
                type="text"
                name="first_name"
                :value="inputs.first_name.value"
                :label="__t('app.first_name')"
                :placeholder="__t('app.first_name')"
                :error="inputs.first_name.error ? inputs.first_name.error_message : null"
                @set="val => inputs.first_name.value = val"
                @remove-error="inputs.first_name.error = false"
                @focus-out="val => setValue(inputs.first_name, val)"
              />
              <Input
                id="last_name"
                type="text"
                name="last_name"
                :value="inputs.last_name.value"
                :label="__t('app.last_name')"
                :placeholder="__t('app.last_name')"
                :error="inputs.last_name.error ? inputs.last_name.error_message : null"
                @set="val => inputs.last_name.value = val"
                @remove-error="inputs.last_name.error = false"
                @focus-out="val => setValue(inputs.last_name, val)"
              />
            </div>
            <Input
              id="email"
              type="email"
              name="email"
              :disabled="authenticated"
              :value="inputs.email.value"
              :label="__t('app.email')"
              :placeholder="__t('app.email')"
              :error="inputs.email.error ? inputs.email.error_message : null"
              @set="val => inputs.email.value = val"
              @remove-error="inputs.email.error = false"
              @focus-out="val => setEmailValue(inputs.email, val)"
            />
            <Input
              id="phone"
              type="tel"
              name="phone"
              :value="inputs.phone.value"
              :label="__t('app.phone')"
              :placeholder="__t('app.phone')"
              :error="inputs.phone.error ? inputs.phone.error_message : null"
              @set="val => inputs.phone.value = val"
              @remove-error="inputs.phone.error = false"
              @focus-out="val => setValue(inputs.phone, val)"
            />

            <template v-if="!authenticated">
              <div class="toggle-group">
                <RegisterToggle />
              </div>

              <template v-if="registerData?.registering">
                <Input
                  id="password"
                  type="password"
                  name="password"
                  :value="inputs.password.value"
                  :label="__t('app.password')"
                  :placeholder="__t('app.password')"
                  :error="inputs.password.error ? inputs.password.error_message : null"
                  @set="val => inputs.password.value = val"
                  @remove-error="inputs.password.error = false"
                  @focus-out="val => setValue(inputs.password, val)"
                />
                <Input
                  id="password_repeat"
                  type="password"
                  name="password_repeat"
                  :value="inputs.password_repeat.value"
                  :label="__t('app.password-repeat')"
                  :placeholder="__t('app.password-repeat')"
                  :error="inputs.password_repeat.error ? inputs.password_repeat.error_message : null"
                  @set="val => inputs.password_repeat.value = val"
                  @remove-error="inputs.password_repeat.error = false"
                  @focus-out="val => setValue(inputs.password_repeat, val)"
                />
              </template>
            </template>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <AppButton
          :class="{'disabled': buttonDisabled}"
          @click="submitForm"
        >
          {{ __t('checkout.user-info.submit') }}
        </AppButton>
        <span @click="close">{{ __t('app.modal.cancel') }}</span>
      </div>
    </div>
  </ion-content>
</template>

<script setup>
  import {ref, onBeforeMount, watch, computed} from 'vue';
  import { Icon, Input } from "@/ui";
  import RegisterToggle from "@/modules/order/parts/RegisterToggle.vue";
  import { $showToast, __t } from "@/globals";
  import { checkEmail } from "@/modules/global/helpers/domHelper";
  import { modalController } from "@ionic/vue";
  import AppButton from "@/ui/button/AppButton.vue";
  import User from "@/resources/User.js";
  import { userService } from "@/services/app/userService.js";
  import store from "@/store/store.js";
  import axios from "axios";
  import {apiUrl} from "@/configuration/api.js";
  import {IonHeader} from "@ionic/vue";

  const user = ref(null);
  const authenticated = ref(null);
  const registerData = ref(null);
  const registerDataUpdated = computed(() => store.getters['isRegisterDataUpdated']);

  const inputs = ref({
    first_name: { value: null, error: false, error_message: __t('error.input.first_name') },
    last_name: { value: null, error: false, error_message: __t('error.input.last_name') },
    email: { value: null, error: false, error_message: __t('error.input.email') },
    phone: { value: null, error: false, error_message: __t('error.input.phone') },
    password: { value: null, error: false, error_message: __t('error.input.password') },
    password_repeat: { value: null, error: false, error_message: __t('error.input.password-repeat') },
  });

  const buttonDisabled = ref(false);

  const setValue = (input, val) => {
    input.value = val;
  };

  const setEmailValue = (input, val) => {
    input.value = val;
    validateEmail();
  };

  const validate = async () => {
    let response = validateContactDetails();

    if (!response) return false;
    return response;
  };

  const initData = async () => {
    authenticated.value = await userService.isAuthenticated();
    registerData.value = await userService.getRegisterData();
    user.value = await userService.getUser();

    inputs.value.first_name.value = user.value?.first_name;
    inputs.value.last_name.value = user.value?.last_name;
    inputs.value.phone.value = user.value?.phone;
    inputs.value.email.value = user.value?.email;
    inputs.value.password.value = user.value?.password;
    inputs.value.password_repeat.value = user.value?.password;
  };

  const submitForm = async () => {
    if (await validate()) {
      const payload = new User({
        first_name: inputs.value.first_name.value,
        last_name: inputs.value.last_name.value,
        email: inputs.value.email.value,
        phone: inputs.value.phone.value,
        password: inputs.value.password.value,
        auth_provider: user.value?.auth_provider
      });

      if(authenticated.value) {
        payload.id = user.value.id;
        payload.auth_provider = user.value.auth_provider;
        payload.email_verified = user.value.email_verified;
        payload.profile_image_path = user.value.profile_image_path;
      }

      await userService.setUser(payload);

      $showToast(__t('success.order-delivery-details'), 'success');
      close();
    } else {
      $showToast(__t('error.order-delivery-details'), 'error');
    }
  };

  const close = () => {
    modalController.dismiss();
  };

  const validateContactDetails = () => {
    let response = true;

    Object.entries(inputs.value).forEach(([key, inputObj]) => {
      inputObj.error = false;

      if (key === 'id') return;

      if (key === 'password' || key === 'password_repeat') {
        if (registerData.value.registering) {

          if (!inputObj.value) {
            inputObj.error = true;
            response = false;
          }
          if (inputs.value.password.value?.length < 8) {
            inputs.value.password.error = true;
            response = false;
          }
          if (inputs.value.password.value !== inputs.value.password_repeat.value) {
            inputs.value.password_repeat.error = true;
            response = false;
          }
        }
        return;
      }

      if (!inputObj.value) {
        inputObj.error = true;
        response = false;
      }

      if (key === 'email' && !checkEmail(inputObj.value)) {
        inputObj.error = true;
        response = false;
      }
    });

    return response;
  };

  const validateEmail = async () => {
    buttonDisabled.value = false;

    if(registerData.value.registering) {
      await axios.post(apiUrl('check-email'), {email: inputs.value.email.value})
        .then(({data}) => {
          if(data.data.exists) {
            inputs.value.email.error = true;
            inputs.value.email.error_message = __t('error.email-exists');
            buttonDisabled.value = true;
          } else {
            inputs.value.email.error = false;
            inputs.value.email.error_message = __t('error.input.email');
            buttonDisabled.value = false;
          }
        })
        .catch(() => {
          inputs.value.email.error = true;
        });
    }
  };

  onBeforeMount(async () => {
    await initData();
  });

  watch(registerDataUpdated, async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      registerData.value = await userService.getRegisterData();
      await validateEmail();
    }
  });
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-mini-contact {
  --height: 100%;
  --border-radius: 1rem;
}
.modal-contact-fields {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;

  .r2e-input {
    margin-bottom: var(--margin-m);
  }

  @media(max-height: 800px) and (min-width: 720px) {
    border-radius: 1rem;
  }

  .modal-header {
    margin-bottom: var(--margin-l);

    h3 {
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      svg {
        width: 2rem;
        height: 2rem;
        margin-right: var(--margin-s);
      }
    }
  }

  .toggle-group {
    margin: var(--margin-m);
    margin-top: var(--margin-l);
    display: flex;
    justify-content: center;
  }

  .modal-main, .modal-footer {
    max-width: 40rem;
    margin: auto auto var(--margin-l);
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .button-wrapper {
      width: 100%;
      max-width: 36rem;
    }

    span {
      font-size: 1.2rem;
      text-align: center;
      margin-top: var(--margin-m);
      font-weight: 500;
      color: var(--color-grey-500);
      cursor: pointer;

      &:hover {
        color: var(--color-black);
      }
    }
  }
}
</style>
