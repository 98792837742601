import apiClient from "@/core/apiClient.js";

export class UserRegistrationRepository {
  async registerByEmail(payload) {
    try {
      const {data} = await apiClient.post('register', payload);

      return data.data;
    } catch (error) {
      console.error('Failed to register by email:', error);
      throw new Error(error);
    }
  }

  async verifyRegisterCode(code, email) {
    try {
      const {data} = await apiClient.post('verify-register-code', {
        register_code: code,
        email: email
      });

      return data.data;
    } catch (error) {
      console.error('Failed to verify register code:', error);
      throw new Error(error);
    }
  }

  async resendVerificationCode(email) {
    try {
      const {data} = await apiClient.post('resend-register-code', {
        email: email
      });

      return data.data;
    } catch (error) {
      console.error('Failed to resend verification code:', error);
      throw new Error(error);
    }
  }
}
