import apiClient from '@/core/apiClient';

export class DiscountRepository {
  async validateDiscount(code) {
    try {
      const {data} = await apiClient.post('customer/discount-codes/validate', {code});
      return data.data;
    } catch (error) {
      console.error('Failed to validate discount:', error);
      throw error;
    }
  }
}
