import store from "@/store/store.js";
import User from "@/flows/user/user/models/User.js";
import {userStorage} from "@/flows/user/user/stores/UserStorage.js";
import AuthenticationToken from "@/flows/user/authentication/models/AuthenticationToken.js";
import {UserRepository} from "@/flows/user/user/repositories/UserRepository.js";

class UserService {
  constructor() {
    this.userRepository = new UserRepository();
    this.userStorage = userStorage;
  }

  // User

  async getUser() {
    try {
      return await this.userStorage.get('user');
    } catch (error) {
      console.error('Failed to get user:', error);
      return {};
    }
  }

  async initUser(payload) {
    try {
      await this.userStorage.set('user', null);

      const user = new User(payload);

      await this.userStorage.set('user', user);

    } catch (error) {
      console.error('Failed to initialize user:', error);
      throw new Error(error);
    }
  }

  async updateUser({first_name, last_name, phone}) {
    try {
      const payload = {
        first_name,
        last_name,
        phone
      };

      await this.userRepository.updateUser(payload);

      await this.updateLocalUser(payload);
    } catch (error) {
      console.error('Failed to update user:', error);
      throw new Error(error);
    } finally {
      await this.sendUpdatedEvent();
    }
  }

  async updateLocalUser({first_name, last_name, phone}) {
    try {
      const user = await this.getUser();

      user.first_name = first_name;
      user.last_name = last_name;
      user.phone = phone;

      await this.userStorage.set('user', user);
    } catch (error) {
      console.error('Failed to update user:', error);
      throw new Error(error);
    } finally {
      await this.sendUpdatedEvent();
    }
  }

  async requestUserDeletion(email) {
    try {
      return await this.userRepository.requestUserDeletion(email);
    } catch (error) {
      console.error('Failed to delete account:', error);
      throw error;
    }
  }

  // Token

  async setToken(value) {
    try {
      await this.userStorage.set('auth_token_data', null);

      const authToken = new AuthenticationToken(value);

      console.log('authToken', authToken);

      await this.userStorage.set('auth_token_data', authToken);
    } catch (error) {
      console.error('Failed to set token:', error);
      throw new Error('Failed to set token:', error);
    }
  }

  async getToken() {
    try {
      return await this.userStorage.get('auth_token_data');
    } catch (error) {
      console.error('Failed to get token:', error);
      throw new Error('Failed to get token:', error);
    }
  }

  async setProvider(value) {
    try {
      const user = await this.getUser();

      user.auth_provider = value;

      await this.userStorage.set('user', user);
    } catch (error) {
      console.error('Failed to set provider:', error);
      throw new Error('Failed to set provider:', error);
    } finally {
      await this.sendUpdatedEvent();
    }
  }

  async isAuthenticated() {
    const user = await this.getUser();
    const token = await this.getToken();

    return (user.id && token.access_token) || false;
  }

  async isEmailVerified() {
    const user = await this.getUser();
    return user.email_verified;
  }

  async sendUpdatedEvent() {
    await store.dispatch('updatedUser');
  }
}

export const userService = new UserService();
