import Address from "@/resources/Address.js";

export default class CartEstablishment {
  constructor(establishment) {
    this.id = null;
    this.slug = null;
    this.name = null;
    this.status = null;
    this.default_preparation_time = null;
    this.logo_path = null;
    this.delivery = 'both';
    this.delivery_price = 0.00;
    this.min_order_amount = 0.00;
    this.contact = null;
    this.address = new Address();
    this.open_time = {
      day: null,
      time: null
    };
    this.business_hours = [];

    if(establishment){
      this.init(establishment);
    }
  }

  init(data){
    this.id = data.id || null;
    this.slug = data.slug || null;
    this.name = data.name || null;
    this.status = data.status || null;
    this.default_preparation_time = data.default_preparation_time || null;
    this.logo_path = data.logo_path || null;
    this.delivery = data.delivery || 'both';
    this.delivery_price = data.delivery_price || 0.00;
    this.min_order_amount = data.min_order_amount || 0.00;
    this.contact = {
      email: data.contact.email || null,
      phone: data.contact.phone || null
    };
    this.address = new Address(data.address);
    this.open_time = {
      day: data.open_time.day || null,
      time: data.open_time.time || null
    };
    this.business_hours = data.business_hours.map((dayConfig) => {
      return {
        day: dayConfig.day || null,
        open_time: dayConfig.open_time || null,
        close_time: dayConfig.close_time || null,
        closed: dayConfig.closed || 0,
        type: dayConfig.type || null,
        open_time_formatted: dayConfig.open_time_formatted || null,
        close_time_formatted: dayConfig.close_time_formatted || null,
      };
    });
  }
}
