import {discountStorage} from '../storage/discountStorage';
import {DiscountCode} from "@/modules/discount/models/DiscountCode.js";
import {DiscountRepository} from "@/modules/discount/repositories/DiscountRepository.js";

class DiscountService {

  constructor() {
    this.discountRepository = new DiscountRepository();
    this.discountStorage = discountStorage;
  }

  async addAutoAppliedDiscountCode(data) {
    try {
      const discountCode = new DiscountCode(data);

      // check if code is already stored
      const storedDiscountCodes = await discountStorage.getDiscountCodes();

      const codes = Object.values(storedDiscountCodes);

      const storedDiscountCode = codes.find(c => c.code === discountCode.code);

      if (!storedDiscountCode) {
        await discountStorage.saveDiscountCode(discountCode);
      }

    } catch (error) {
      console.error('Failed to add auto applied discount:', error);
      throw error;
    }
  }

  async checkForAutoAppliedDiscountCode() {
    try {
      const storedDiscountCodes = await discountStorage.getDiscountCodes();

      const codes = Object.values(storedDiscountCodes);

      return codes.find(code => code.auto_apply && code.enabled);

    } catch (error) {
      console.error('Failed to check for auto applied discount:', error);
      return null;
    }
  }

  async dismissDiscountCode(discountCode) {
    try {
      const storedDiscountCodes = await discountStorage.getDiscountCodes();

      const codes = Object.values(storedDiscountCodes);

      const storedDiscountCode = codes.find(c => c.code === discountCode.code);

      if (storedDiscountCode) {
        console.log(storedDiscountCode);
        storedDiscountCode.dismissed = true;
        await discountStorage.saveDiscountCode(storedDiscountCode);
      }
    } catch (error) {
      console.error('Failed to set discount code dismissed:', error);
      throw error;
    }
  }

  async removeDiscountCode(discountCode) {
    try {
      const storedDiscountCodes = await discountStorage.getDiscountCodes();

      const codes = Object.values(storedDiscountCodes);

      const storedDiscountCode = codes.find(c => c.code === discountCode.code);

      if (storedDiscountCode) {
        await discountStorage.removeDiscountCode(storedDiscountCode);
      }
    } catch (error) {
      console.error('Failed to remove discount code:', error);
      throw error;
    }
  }

  async validateAndApplyDiscountCode(code) {
    try {
      const discountCode = await this.discountRepository.validateDiscount(code);

      if(discountCode === null) {
        return null;
      }

      const discountCodeObject = new DiscountCode(discountCode);

      await discountStorage.saveDiscountCode(discountCodeObject);

      return discountCode;
    } catch (error) {
      console.error('Failed to validate and apply discount:', error);
      throw error;
    }
  }

  async checkForDiscountApplied() {
    try {
      const storedDiscountCodes = await discountStorage.getDiscountCodes();

      const codes = Object.values(storedDiscountCodes);

      const autoAppliedCode = codes.find(code => code.auto_apply && code.enabled);

      if (autoAppliedCode) {
        return autoAppliedCode;
      }

      return codes.find(code => code.applied);
    } catch (error) {
      console.error('Failed to check for discount applied:', error);
      return null;
    }
  }
  // async updateGlobalDiscount(discountCode) {
  //   if (!discountCode) return;
  //   await discountStorage.saveDiscountCode(discountCode);
  // }
  //
  // async applyDiscount(code, orderId, orderAmount) {
  //   const discountCode = await this.validateDiscount(code);
  //
  //   if (orderAmount < discountCode.min_order_amount) {
  //     throw new Error(`Order amount must be at least ${discountCode.min_order_amount}`);
  //   }
  //
  //   await discountStorage.incrementUserUsage(code);
  //
  //   return await discountRepository.applyDiscount(code, orderId);
  // }
  //
  // async validateDiscount(code) {
  //
  //   const storedCodes = await discountStorage.getDiscountCodes();
  //   const discountCode = storedCodes[code];
  //
  //   const userUsage = await discountStorage.getUserUsage();
  //   const userUsageCount = userUsage[code] || 0;
  //
  //   if (discountCode.max_uses_per_user && userUsageCount >= discountCode.max_uses_per_user) {
  //     throw new Error('You have reached the maximum usage for this discount code');
  //   }
  //
  //   return discountCode;
  // }

  // async getAvailableDiscounts() {
  //   const storedCodes = await discountStorage.getDiscountCodes();
  //   const userId = session.getters['user/id'];
  //   const userUsage = await discountStorage.getUserUsage(userId);
  //
  //   return Object.values(storedCodes).filter(code => {
  //     const now = new Date();
  //     return code.enabled &&
  //       (!code.valid_from || new Date(code.valid_from) <= now) &&
  //       (!code.valid_to || new Date(code.valid_to) >= now) &&
  //       (!code.max_uses || code.usage_count < code.max_uses) &&
  //       (!code.max_uses_per_user || (userUsage[code.code] || 0) < code.max_uses_per_user);
  //   });
  // }
}

export const discountService = new DiscountService();
