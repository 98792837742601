import PlatformService from "@/services/PlatformService.js";
import {UserAuthenticationRepository} from "@/flows/user/authentication/repositories/UserAuthenticationRepository.js";
import {userService} from "@/flows/user/user/services/UserService.js";
import {
    SocialAuthenticationNativeHelper
} from "@/flows/user/authentication/services/helpers/social/SocialAuthenticationNativeHelper.js";
import {
    SocialAuthenticationWebHelper
} from "@/flows/user/authentication/services/helpers/social/SocialAuthenticationWebHelper.js";

class SocialLoginHelper {
    constructor() {
        this.userAuthenticationRepository = new UserAuthenticationRepository();
        this.userService = userService;
    }

    loginWithProvider(provider) {
        switch (provider) {
            case "facebook":
                return this.loginWithFacebook();
            case "google":
                return this.loginWithGoogle();
            case "apple":
                return this.loginWithApple();
            default:
                throw new Error("Invalid provider");
        }
    }

    async loginWithFacebook() {
        // get helper
        const helper = this.getHelper();

        // get provider access token
        const accessToken = await helper.loginWithFacebook();

        // get user and api access token
        const {user, access_token, expires_in} = await this.userAuthenticationRepository.loginWithFacebook(accessToken);

        // set user
        await this.userService.initUser(user);

        // set token
        await this.userService.setToken({access_token, expires_in});

        // set provider
        await this.userService.setProvider('facebook');

    }

    async loginWithGoogle() {
        // get helper
        const helper = this.getHelper();

        // get provider access token
        const accessToken = await helper.loginWithGoogle();

        // get user and api access token
        const {user, access_token, expires_in} = await this.userAuthenticationRepository.loginWithGoogle(accessToken);

        // set user
        await this.userService.initUser(user);

        // set token
        await this.userService.setToken({access_token, expires_in});
    }

    async loginWithApple() {
        // get helper
        const helper = this.getHelper();

        // get provider access token
        const {token, userResponse} = await helper.loginWithApple();

        // get user and api access token
        const {user, access_token, expires_in} = await this.userAuthenticationRepository.loginWithApple(token, userResponse);

        // set user
        await this.userService.initUser(user);

        // set token
        await this.userService.setToken({access_token, expires_in});
    }

    getHelper() {
        if (PlatformService.isNative()) {
            return new SocialAuthenticationNativeHelper();
        } else {
            return new SocialAuthenticationWebHelper();
        }
    }
}

export const socialLoginHelper = new SocialLoginHelper();
