import {socialLoginHelper} from "@/flows/user/authentication/services/helpers/SocialLoginHelper.js";
import {emailLoginHelper} from "@/flows/user/authentication/services/helpers/EmailLoginHelper.js";
import {userStorage} from "@/flows/user/user/stores/UserStorage.js";
import {userRegisterService} from "@/flows/user/authentication/services/UserRegisterService.js";


class UserAuthenticationService {
  constructor() {
    this.userStorage = userStorage;
    this.userRegisterService = userRegisterService;
  }
  async login(payload, provider) {
    try {
      if(provider === "email") {
        return await emailLoginHelper.loginWithEmail(payload);
      } else {
        return await socialLoginHelper.loginWithProvider(provider);
      }
    } catch (error) {
      console.error('Error in login', error);
      throw new Error(error);
    }

  }

  async logout() {
    try {
      await this.userStorage.clear();
      await this.userRegisterService.removeRegisterData();
    } catch (error) {
      console.error('Error in logout', error);
      throw new Error(error);
    }
  }

  // linkSocialAccount
  // async linkSocialAccount(provider) {
  //   // link social account
  // }
}

export const userAuthenticationService = new UserAuthenticationService();
