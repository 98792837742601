import {UserAuthenticationRepository} from "@/flows/user/authentication/repositories/UserAuthenticationRepository.js";
import {userService} from "@/flows/user/user/services/UserService.js";

class EmailLoginHelper {
  constructor() {
    this.userAuthenticationRepository = new UserAuthenticationRepository();
    this.userService = userService;
  }

  async loginWithEmail(payload) {
      // get token data
      const tokenData = await this.userAuthenticationRepository.getToken(payload);

      // if token data is not available, throw an error
      if(!tokenData) {
        throw new Error('Failed to get token data');
      }

      // get user data
      const userResponse = await this.userAuthenticationRepository.getUser(tokenData.access_token);

      // if no user data is available, throw an error
      if(!userResponse) {
        throw new Error('Failed to get user data');
      }

      // set token data
      await this.userService.setToken(tokenData);

      // set user
      await this.userService.initUser(userResponse);

      // set provider
      await this.userService.setProvider('email');
  }

}

export const emailLoginHelper = new EmailLoginHelper();
