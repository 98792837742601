<template>
  <Teleport to="body">
    <div ref="loader-modal" class="modal-wrapper-loading">
      <div class="loading-animation">
        <animation-map-loader class="white" />
      </div>
    </div>
  </Teleport>
</template>

<script>

  import AnimationMapLoader from "@/modules/global/parts/AnimationMapLoader.vue";

  export default {
    name: "ModalLoading",
    components: {AnimationMapLoader},
    emits: ['close'],
  }
</script>

<style lang="scss">
.modal-wrapper-loading {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999999;
  width: 100%;
  height: 100vh;
  background-color: var(--r2e-modal-background);
  display: flex;
  justify-content: center;
  align-items: center;

  div.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 8rem;
      height: 8rem;
      color: #fff;
    }
  }

}
</style>
