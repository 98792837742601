import {catalogueFilterStorage} from "@/flows/app/catalogue/stores/CatalogueFilterStorage.js";
import {CatalogueFilter} from "@/flows/app/catalogue/models/CatalogueFilter.js";

class CatalogueFilterService {
  constructor() {
    this.catalogueFilterStorage = catalogueFilterStorage;
  }

  async getCatalogueFilter() {
    try {
      const data =  await this.catalogueFilterStorage.getCatalogueFilter();

      if(!data) {
        await this.updateCatalogueFilter(new CatalogueFilter());
      }

      return new CatalogueFilter(data);
    } catch (error) {
      console.error('Failed to get catalogue filter:', error);
      return new CatalogueFilter();
    }
  }

  async updateCatalogueFilter(filter) {
    try {

      const catalogueFilter = new CatalogueFilter(filter);

      await this.catalogueFilterStorage.setCatalogueFilter(catalogueFilter);
    } catch (error) {
      console.error('Failed to update catalogue filter:', error);
      throw new Error(error);
    } finally {
      await this.sendUpdatedEvent();
    }
  }

  async clearCatalogueFilter() {
    try {
      await this.catalogueFilterStorage.clear();
    } catch (error) {
      console.error('Failed to clear catalogue filter:', error);
      throw new Error(error);
    } finally {
      await this.sendUpdatedEvent();
    }
  }

  async sendUpdatedEvent() {
    document.dispatchEvent(new Event('filterUpdated'));
  }
}

export const catalogueFilterService = new CatalogueFilterService();
