<template>
  <ion-header>
    <div class="modal-filter-header" :class="{'native-padding': native}">
      <container>
        <h2>{{ __t('app.filter.title') }}</h2>
        <button class="btn-exit" @click="close">
          <Icon name="exit" />
        </button>
      </container>
    </div>
  </ion-header>
  <ion-content>
    <div class="filter">
      <div class="modal-filter-main">
        <container>
          <catalogue-filter
            ref="catalogueFilterComponent"
            hide-title
            @close="close"
          />
        </container>
      </div>
    </div>
  </ion-content>

  <ion-footer style="box-shadow: none; padding-top: var(--padding-s);padding-bottom: var(--padding-s)">
    <div class="modal-filter-footer">
      <container>
        <div class="bottom-actions mb-s">
          <app-button icon="filter" @click="submitFilter">
            {{ __t('app.filter') }}
          </app-button>
        </div>
      </container>
    </div>
  </ion-footer>
</template>

<script setup>
  import {IonHeader, IonContent, IonFooter} from "@ionic/vue";
  import {Icon} from "@/ui/index.js";
  import Container from "@/layout/layout/Container.vue";
  import {modalController} from '@ionic/vue';
  import {onBeforeMount, ref} from "vue";
  import PlatformService from "@/services/PlatformService.js";
  import CatalogueFilter from "@/flows/app/catalogue/components/parts/CatalogueFilter.vue";
  import {__t} from "@/globals.js";
  import AppButton from "@/ui/button/AppButton.vue";

  const native = ref(false);
  const catalogueFilterComponent = ref(null);

  const close = async () => {
    await modalController.dismiss();
  };

  const submitFilter = async () => {
    await modalController.dismiss();
  };

  onBeforeMount(async () => {
    native.value = PlatformService.isNative();
  });
</script>

<style lang="scss">
ion-modal.ion-modal-catalogue-filter {
  --background: var(--r2e-secondary-100);
  background-color: var(--r2e-secondary-100);

  ion-content {
    --background: var(--r2e-secondary-100);
    background-color: var(--r2e-secondary-100);
  }

  .modal-filter-header {
    padding-bottom: var(--padding-m);
    padding-top: var(--padding-m);
    flex-direction: column;

    &.native-padding {
      padding-top: 6rem;
    }

    .container {
      display: flex;
      align-items: center;
    }

    button.btn-exit {
      background-color: transparent;
      margin-bottom: auto;
      margin-left: auto;
      width: 1.4rem;
      height: 1.4rem;
      transition: var(--effect);

      svg {
        width: 1.4rem;
        height: 1.4rem;
        fill: var(--color-black);
      }

      &:hover {
        transform: scale(1.08);
      }
    }
  }

  .modal-main {
    max-height: none;
    padding-bottom: 10rem;
    display: flex;
    width: 100%;
    flex-direction: column;

    .container {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      height: auto;
      padding-bottom: 0;
      padding-right: 0;
    }

    .filter-full-list {
      padding-bottom: var(--ion-safe-area-bottom);
      overflow-y: scroll;
      height: max-content;
      max-height: none;
    }

    .bottom-actions {
      padding: var(--padding-m);
      padding-bottom: calc(var(--ion-safe-area-bottom) + var(--padding-l));
      left: 0;
      position: fixed;
      z-index: 9999;
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--r2e-secondary-100);
    }
  }
}

[data-theme='dark'] {
  ion-modal.ion-modal-filter {
    --background: var(--r2e-secondary-600);

    .modal-filter {
      background-color: var(--r2e-secondary-600);

      .modal-main {
        background-color: var(--r2e-secondary-600);

        .catalogue-filter {
          background-color: var(--r2e-secondary-600);
        }
      }

      .bottom-actions {
        background-color: var(--r2e-secondary-600);

        button {
          background-color: var(--r2e-primary);
        }
      }
    }
  }
}
</style>
