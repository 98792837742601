import {FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import SocialAuthenticationHelper
  from "@/flows/user/authentication/services/helpers/social/SocialAuthenticationHelper.js";

export class SocialAuthenticationWebHelper extends SocialAuthenticationHelper {
  async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth(window.firebase_app);
    const result = await signInWithPopup(auth, provider);

    return result._tokenResponse.oauthAccessToken;
  }

  async loginWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth(window.firebase_app);
    const result = await signInWithPopup(auth, provider);

    return result._tokenResponse.oauthAccessToken;

  }

  async loginWithApple() {
    throw new Error('Apple login is not supported in web');
  }
}
