<template>
  <div class="catalogue-filter">
    <h2 v-if="!hideTitle">
      {{ __t('app.filter.title') }}
    </h2>

    <div v-if="filter" class="filter-full-list">
      <div class="mb-l">
        <div class="filter space-between">
          <h3 @click="toggleProp('delivery')">
            {{ __t('app.filter.delivery') }}
          </h3>
          <toggle
            :active="filter.toggles.delivery"
            @toggle="toggleProp('delivery')"
          />
        </div>
      </div>

      <div class="mb-l">
        <div class="filter space-between">
          <h3 @click="toggleProp('open')">
            {{ __t('app.filter.open') }}
          </h3>
          <toggle
            :active="filter.toggles.open"
            @toggle="toggleProp('open')"
          />
        </div>
      </div>

      <div class="mb-l">
        <div class="filter space-between">
          <h3 @click="toggleProp('ready2help')">
            {{ __t('app.filter.ready2help') }}
          </h3>
          <toggle
            :active="filter.toggles.ready2help"
            @toggle="toggleProp('ready2help')"
          />
        </div>
      </div>

      <!-- Types -->
      <div class="mb-l pt-m">
        <h3 class="mb-m">
          {{ __t('app.filter.results') }}
        </h3>

        <div
          class="filter space-between"
          :class="{
            'active' : filter.types.all}
          "
          @click="toggleType('all')"
        >
          <h4>{{ __t('app.filter.all') }}</h4>
          <checkbox
            :checked="filter.types.all"
          />
        </div>

        <div
          class="filter space-between"
          :class="{'active' : filter.types.restaurant && !filter.types.all }"
          @click="toggleType('restaurant')"
        >
          <h4>{{ __t('app.filter.types.restaurants') }}</h4>
          <checkbox :checked="filter.types.restaurant && !filter.types.all" />
        </div>

        <div
          class="filter space-between"
          :class="{'active' : filter.types.shop && !filter.types.all }"
          @click="toggleType('shop')"
        >
          <h4>{{ __t('app.filter.types.shops') }}</h4>
          <checkbox :checked="filter.types.shop && !filter.types.all" />
        </div>

        <div
          class="filter space-between"
          :class="{'active' : filter.types.night_shop && !filter.types.all }"
          @click="toggleType('night_shop')"
        >
          <h4>{{ __t('app.filter.types.night_shops') }}</h4>
          <checkbox :checked="filter.types.night_shop && !filter.types.all" />
        </div>
      </div>

      <!-- Categories -->
      <div class="mb-l pt-m">
        <h3 class="mb-m">
          {{ __t('app.filter.categories') }}
        </h3>

        <div
          v-for="(cat, i) in filter.categories"
          :key="`cat_${i}`"
          class="filter space-between"
          :class="{'active': cat.active}"
          @click="() => toggleCategory(cat.key)"
        >
          <h4>{{ __t('app.filter.' + cat.key) }}</h4>
          <checkbox :checked="cat.active" />
        </div>
      </div>
    </div>

    <div v-if="withSubmitButton" class="bottom-actions mb-s">
      <app-button icon="filter" @click="submitFilter">
        {{ __t('app.filter') }}
      </app-button>
    </div>
  </div>
</template>


<script setup>
  import {Toggle, Checkbox} from "@/ui/index.js";
  import {defineProps, defineEmits, onBeforeMount, ref} from 'vue';
  import {__t} from "@/globals.js";
  import AppButton from "@/ui/button/AppButton.vue";
  import {catalogueFilterService} from "@/flows/app/catalogue/services/CatalogueFilterService.js";
  import {CatalogueFilter} from "@/flows/app/catalogue/models/CatalogueFilter.js";

  defineProps({
    withSubmitButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false
    },
  });

  const emit = defineEmits(['close']);
  const filter = ref(null);

  const toggleProp = async (key) => {
    filter.value.toggle(key);
    await updateFilter(filter.value);
  };

  const toggleType = async (type) => {
    filter.value.toggleType(type);
    await updateFilter(filter.value);
  };

  const toggleCategory = async (key) => {
    filter.value.toggleCategory(key);
    await updateFilter(filter.value);
  };

  const submitFilter = () => {
    emit('close')
  };

  const updateFilter = async (payload) => {
    const filterObject = JSON.parse(JSON.stringify(payload));

    await catalogueFilterService.updateCatalogueFilter(filterObject);
  }

  onBeforeMount(async () => {
    filter.value = await catalogueFilterService.getCatalogueFilter();
  })
</script>


<style lang="scss">
.catalogue-filter {
  background-color: var(--r2e-secondary-100);
  min-height: 100vh;

  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: var(--margin-l);
  }

  h3 {
    font-weight: 700;
    font-size: 1.2rem;
  }

  div.filter {
    align-items: center;
    margin-bottom: var(--margin-m);

    h3 {
      font-weight: 700;
      font-size: 1.2rem;
      cursor: pointer;
    }

    h4 {
      font-weight: 600;
      font-size: 1.2rem;
      cursor: pointer;
    }

    &.active {
      h4 {
        color: var(--r2e-primary);
      }
    }
  }
}

[data-theme='dark'] {
  .catalogue-filter {
    background-color: var(--r2e-secondary);

    h2 {
      color: var(--r2e-white);
    }

    h3 {
      color: var(--r2e-white);
    }

    div.filter {
      h3 {
        color: var(--r2e-white);
      }

      h4 {
        color: var(--r2e-secondary-200);
      }
    }
  }
}

</style>
