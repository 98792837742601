<template>
  <app-modal-mini
    :button-disabled="disabled"
    :submit-txt="__t('checkout.discount.submit')"
    @submitted="validateCode"
  >
    <template #header>
      <h3>
        <Icon name="discount" />
        {{ __t('checkout.discount.title') }}
      </h3>
    </template>

    <template #main>
      <Input
        id="code"
        :value="discountCode"
        :placeholder="__t('checkout.discount.title')"
        type="text"
        @set="setCode"
      />
    </template>
  </app-modal-mini>
</template>

<script setup>
  import {onMounted, ref} from 'vue';
  import { $showToast, __t } from '@/globals';
  import { Icon, Input } from '@/ui';
  import AppModalMini from '@/modals/AppModalMini.vue';
  import { modalController } from '@ionic/vue';
  import axios from 'axios';
  import { apiUrl } from '@/configuration/api.js';
  import {valuta} from "@/modules/global/helpers/domHelper.js";
  import {cartService} from "@/services/app/cartService.js";
  import {discountService} from "@/modules/discount/services/DiscountService.js";

  const disabled = ref(true);
  const discountCode = ref(null);
  const cartSummary = ref(null);

  const setCode = (val) => {
    discountCode.value = val;
    disabled.value = false;
  };

  const validateCode = async () => {
    try {
      const storedDiscountCode = await discountService.validateAndApplyDiscountCode(discountCode.value);

      if(!storedDiscountCode) {
        return $showToast(__t('error.discount-code'), 'error');
      }

      await cartService.setDiscountCode(storedDiscountCode);

      await modalController.dismiss();

      $showToast(__t('success.checkout.discount-code'), 'success');
    } catch (e) {
      $showToast(__t('error.discount-code.claimed'), 'error');
      console.error(e);
    }
  };

  onMounted(async () => {
    cartSummary.value = await cartService.getCartSummary();
    discountCode.value = cartSummary.value.discount_code;
  });
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-discount-code {
  --height: 38rem;
}
</style>
