import {Drivers, Storage} from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {DiscountCode} from "../models/DiscountCode.js";

const STORAGE_KEYS = {
  DISCOUNT_CODES: 'discount_codes',
  USER_USAGE: 'discount_usage'
};

class DiscountStorage {
  constructor() {
    this.storage = new Storage([Drivers.IndexedDB, CordovaSQLiteDriver._driver]);
    this.storage.create();
  }

  async saveDiscountCode(discountCode) {
    try {
      const codes = await this.getDiscountCodes();

      codes[discountCode.code] = discountCode;
      await this.storage.set(STORAGE_KEYS.DISCOUNT_CODES, codes);
    } catch (error) {
      console.error('Failed to save discount code:', error);
    }
  }

  async getDiscountCodes() {
    try {
      return await this.storage.get(STORAGE_KEYS.DISCOUNT_CODES) || [];
    } catch (error) {
      console.error('Failed to get discount codes:', error);
      return {};
    }
  }

  async getUserUsage() {
    try {
      const usages = await this.storage.get(STORAGE_KEYS.USER_USAGE) || {};
      return usages || {};
    } catch (error) {
      console.error('Failed to get user usage:', error);
      return {};
    }
  }

  async incrementUserUsage(discountCode) {
    try {
      const usages = await this.storage.get(STORAGE_KEYS.USER_USAGE) || {};

      if (!usages[discountCode]) {
        usages[discountCode] = 0;
      }
      usages[discountCode]++;
      await this.storage.set(STORAGE_KEYS.USER_USAGE, usages);
    } catch (error) {
      console.error('Failed to increment user usage:', error);
    }
  }

  async removeDiscountCode(discountCode) {
    try {
      const codes = await this.getDiscountCodes();

      delete codes[discountCode.code];
      await this.storage.set(STORAGE_KEYS.DISCOUNT_CODES, codes);
    } catch (error) {
      console.error('Failed to remove discount code:', error);
    }
  }
}

export const discountStorage = new DiscountStorage();
