<template>
  <app-modal-mini
    :button-disabled="false"
    @submitted="submit"
  >
    <template #header>
      <h3 v-if="paymentMethod">
        <Icon :name="paymentMethod === 'online' ? 'card' : 'cash'" />
        {{ __t('checkout.payment-method.title') }}
      </h3>
    </template>

    <template #main>
      <R2eSelect
        id="select-payment-method"
        :placeholder="__t('app.select-payment-method')"
        :selected="choice"
        :options="options"
        :label="__t('app.payment-method')"
        @setter="val => setMethod(val)"
      />

      <div class="providers">
        <template v-if="paymentMethod">
          <template v-if="deliveryMode !== 'delivery'">
            <p v-html="__t('checkout.payment-method.online-no-cash.body')" />
            <p v-html="__t('checkout.payment-method.online.body')" />
          </template>

          <p v-else v-html="__t('checkout.payment-method.online.body')" />

          <div v-if="paymentMethod === 'online'" class="list">
            <Icon name="kbc" />
            <Icon name="belfius" />
            <Icon name="bancontact" />
            <Icon name="mastercard" />
            <Icon name="visa" />
          </div>
        </template>
      </div>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import { ref, onBeforeMount } from 'vue';
  import AppModalMini from "@/modals/AppModalMini.vue";
  import { $showToast, __t } from "@/globals";
  import { Icon } from "@/ui";
  import { modalController } from "@ionic/vue";
  import {preferenceService} from "@/services/app/preferenceService.js";
  import R2eSelect from "@/ui/select/R2eSelect.vue";
  import {cartService} from "@/services/app/cartService.js";


  const paymentMethod = ref(null);
  const deliveryMode = ref(null);
  const choice = ref('online');

  const options = ref([
    {
      value: true,
      label: __t('checkout.payment-method.online'),

    },
    {
      value: false,
      label: __t('checkout.payment-method.cash'),
    }
  ]);

  const setMethod = (val) => {
    console.log(val);
    choice.value = val;
    paymentMethod.value = val ? 'online' : 'cash';
  };



  onBeforeMount(async () => {
    paymentMethod.value = await preferenceService.getPaymentMethod();
    deliveryMode.value = await cartService.getDeliveryMode();

    choice.value = paymentMethod.value === 'online';

    if(deliveryMode.value !== 'delivery') {
      options.value = options.value.filter(option => option.value);
    }
  });

  const submit = async () => {
    await preferenceService.setPaymentMethod(choice.value ? 'online' : 'cash');

    await modalController.dismiss();

    $showToast(__t('success.checkout.payment-method'), 'success');
  };
</script>


<style lang="scss">
.ion-modal-mini.ion-modal-mini-payment {
  --height: 53rem;
}

.providers {
  max-width: 38rem;
  margin: auto;

  p {
    text-align: center;
    margin: var(--margin-m) 0;
    font-weight: 600;
    color: var(--r2e-secondary-200);
    font-size: 1.1rem;
  }

  .list {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 24.7rem;

    svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}
</style>
