// services/preferencesStorage.js
import {Drivers, Storage} from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Storage keys as constants
const STORAGE_KEYS = {
  PREFERENCES: 'register_data_db',
};

class RegisterDataStorage {

  constructor() {
    this.storage = new Storage([Drivers.IndexedDB, CordovaSQLiteDriver._driver]);
    this.storage.create();
  }

  async setRegisterData(preferences) {
    try {
      await this.storage.set(STORAGE_KEYS.PREFERENCES, preferences);
    } catch (error) {
      console.error('Failed to set register data:', error);
    }
  }

  async getRegisterData() {
    try {
      return await this.storage.get(STORAGE_KEYS.PREFERENCES) || {};
    } catch (error) {
      console.error('Failed to get register data:', error);
      return {};
    }
  }

  async clear() {
    try {
      await this.storage.remove(STORAGE_KEYS.PREFERENCES);
    } catch (error) {
      console.error('Failed to clear register data:', error);
    }
  }

  async set(key, value) {
    try {
      const settings = await this.getRegisterData();
      settings[key] = value;
      await this.setUser(settings);
    } catch (error) {
      console.error(`Failed to set register data property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const settings = await this.getRegisterData();
      return settings[key];
    } catch (error) {
      console.error(`Failed to get register data property "${key}":`, error);
    }
  }
}

export const registerDataStorage = new RegisterDataStorage();
