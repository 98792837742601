import {addressDataStorage} from "@/flows/app/address/stores/AddressDataStorage.js";
import Address from "@/flows/app/address/models/Address.js";
import store from "@/store/store.js";

class AddressService {

  constructor() {
    this.addressDataStorage = addressDataStorage;
  }
  async getAddress() {
    try {
      const address = await this.addressDataStorage.get('current_address');

      if (!address) {
        return null;
      }

      return new Address(address);
    } catch (error) {
      console.error('Failed to get address:', error);
      throw new Error(error);
    }
  }

  async setAddress(payload) {
    try {
      const address = new Address(payload);

      await this.addressDataStorage.set('current_address', address);
    } catch (error) {
      console.error('Failed to set address:', error);
      throw new Error(error);
    } finally {
      await this.sendUpdatedEvent();
    }
  }

  async getAddressString() {
    try {
      const address = await this.getAddress();

      if (!address) {
        return '';
      }

      return address.getAddressString();
    } catch (error) {
      console.error('Failed to get address string:', error);
      throw new Error(error);
    }
  }

  async getAddressCoordinates() {
    try {
      const address = await this.getAddress();

      if (!address) {
        return null;
      }

      return address.getCoordinates();
    } catch (error) {
      console.error('Failed to get address coordinates:', error);
      throw new Error(error);
    }
  }

  async sendUpdatedEvent() {
    document.dispatchEvent(new Event('addressUpdated'));
  }
}

export const addressService = new AddressService();
