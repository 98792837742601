import moment from "moment";

function getEstablishmentConfiguration(establishment){
  if(!establishment){
    return null;
  }

  const configuration = {
    minDate: null, // You can set this based on your requirements
    maxDate: null, // You can set this based on your requirements
    disabledDates: [],
    minTime: {},
    maxTime: {}
  };

  const dayMapping = {
    'sunday': 0,
    'monday': 1,
    'tuesday': 2,
    'wednesday': 3,
    'thursday': 4,
    'friday': 5,
    'saturday': 6
  };

  let earliestOpenTime = '23:59:59';
  let latestCloseTime = '00:00:00';

  establishment.business_hours.forEach((dayConfig) => {
    const dayNumber = dayMapping[dayConfig.day.toLowerCase()];

    if (dayConfig.closed) {
      // If the establishment is closed on this day, add its number to disabledDates
      configuration.disabledDates.push(dayNumber);
    } else {
      // Update the earliest open time and latest close time
      earliestOpenTime = dayConfig.open_time < earliestOpenTime ? dayConfig.open_time : earliestOpenTime;
      latestCloseTime = dayConfig.close_time > latestCloseTime ? dayConfig.close_time : latestCloseTime;
    }
  });

  // Convert the earliest open time and latest close time to the required format
  const [minHours, minMinutes, minSeconds] = earliestOpenTime.split(':');
  const [maxHours, maxMinutes, maxSeconds] = latestCloseTime.split(':');

  configuration.minTime = { hours: minHours, minutes: minMinutes, seconds: minSeconds };
  configuration.maxTime = { hours: maxHours, minutes: maxMinutes, seconds: maxSeconds };

  // Get the current time
  const currentTime = moment();
  const currentHours = currentTime.format('HH');
  const currentMinutes = currentTime.format('mm');
  const currentSeconds = currentTime.format('ss');

  // Compare the current time with minTime
  const currentMoment = moment(`${currentHours}:${currentMinutes}:${currentSeconds}`, 'HH:mm:ss');
  const minMoment = moment(`${minHours}:${minMinutes}:${minSeconds}`, 'HH:mm:ss');

  if (currentMoment.isBefore(minMoment)) {
    configuration.startTime = configuration.minTime;
  } else {
    configuration.startTime = { hours: currentHours, minutes: currentMinutes, seconds: currentSeconds };
  }

  // Set minDate to today using Moment.js
  configuration.minDate = moment().toDate();

  // Set maxDate to 30 days from today using Moment.js
  configuration.maxDate = moment().add(14, 'days').toDate();

  return configuration;
}

export {
  getEstablishmentConfiguration
}
