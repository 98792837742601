export class AppConfiguration {
  constructor(data) {
    this.app_code = data.app_code || '';
    this.description = data.description || '';
    this.version = data.version || '';
    this.state = data.state || 'alive';
  }

  getAppState() {
    return this.state;
  }
}
