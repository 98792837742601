<template>
  <app-modal-mini
    :close-button-txt="__t('modal.close.button')"
    :submit-txt="__t('modal.discount.global.submit')"
    @submitted="claimDiscountCode"
  >
    <template #header>
      <h3>{{ __t('modal.discount.global.title') }}</h3>
    </template>

    <template #main>
      <p v-html="__t('modal.discount.global.body', { amount: discountCode.discount_amount })" />
      <span class="discount">
        <b>- {{ valuta(discountCode.discount_amount) }}</b>
        <b class="code"><Icon name="check" /> {{ discountCode.code }}</b>
      </span>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import {defineProps} from 'vue';
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {$showToast, __t} from "@/globals.js";
  import {valuta} from "@/modules/global/helpers/domHelper.js";
  import {Icon} from "@/ui/index.js";
  import {cartService} from "@/services/app/cartService.js";
  import {modalController} from "@ionic/vue";

  const props = defineProps({
    discountCode : {
      type: Object,
      required: true
    }
  });

  const claimDiscountCode = async () => {
    try {
      await cartService.setDiscountCode(props.discountCode);

      await modalController.dismiss();
    } catch (e) {
      $showToast(__t('error.discount-code.claimed'), 'error');
      console.error(e);
    }
  };
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-global-discount-code{
  --height: 40rem;

  .modal-header {
    max-width: 40rem;
    margin: auto auto var(--margin-m);
  }

  .modal-main {
    margin-bottom: 0;
  }

  p {
    font-size: 1.2rem;
    margin: auto;
    max-width: 40rem;
    color: var(--r2e-secondary-200);
    line-height: 1.7;
  }

  .discount {
    padding: var(--padding-s);
    border-radius: 1rem;
    max-width: 36rem;
    background-color: var(--r2e-success-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--margin-m) auto;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--r2e-success);

    b {
      display: flex;
      align-items: center;
      font-size: 2.6rem;

      &.code {
        font-size: 1.8rem;
        font-weight: 600;
        color: var(--r2e-success);
      }
    }

    i {
      margin-right: var(--margin-xs);
      background-color: var(--r2e-success);
      height: 1.4rem;
      width: 1.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 0.8rem;
        height: 0.8rem;
        color: var(--r2e-success-100);
      }
    }
  }
}
</style>
