import {CatalogueRepository} from "@/flows/app/catalogue/repositories/CatalogueRepository.js";
import {catalogueFilterService} from "@/flows/app/catalogue/services/CatalogueFilterService.js";

class CatalogueService {

  constructor() {
    this.catalogueRepository = new CatalogueRepository();
    this.catalogueFilterService = catalogueFilterService;
  }

  async getCatalogue(latitude, longitude) {
    try {
      const filter = await this.catalogueFilterService.getCatalogueFilter();

      const filterPayload = filter.transform();

      const establishments = await this.catalogueRepository.fetchCatalogue(latitude, longitude, filterPayload);

      return this.sortEstablishments(establishments);

    } catch (error) {
      console.error('Failed to fetch catalogue:', error);
      throw new Error(error);
    }
  }

  getEstablishment(establishment_slug, longitude, latitude) {
    try {
      return this.catalogueRepository.fetchEstablishment(establishment_slug, longitude, latitude);
    } catch (error) {
      console.error('Failed to fetch establishment:', error);
      throw new Error(error);
    }
  }

  sortEstablishments(establishments) {
    return establishments.sort((a, b) => {
      if (a.is_open && !b.is_open) {
        return -1;
      } else if (!a.is_open && b.is_open) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}

export const catalogueService = new CatalogueService();
